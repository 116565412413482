<template>
  <section class="purchase-section" id="sect1">
    <div class="instr">
        <img class="big-sale-img" src="/assets/image/2024/instr.png" />
      </div>
      <div class="shur updown" :style="`--delay: ${Math.random() * 2}s;`">
        <img class="big-sale-img" src="/assets/image/2024/shur.png" />
      </div>
    <div class="purchase"> 
    
      <div class="big-sale">
        <img class="big-sale-img" src="/assets/image/big_sale.png" />
      </div>
     
      <h1 class="purchase-title">Совершай <br/> покупки <br/> от 3000 рублей</h1>
      <div class="purchase-text">
        И участвуй в еженедельном розыгрыше призов
      </div>
      <div class="purchase-btns">
        <button class="btns btn-orange" data-href="#section3" @click="link($event)">Хочу участвовать</button>
        <button class="btns btn-linear" data-href="#form" @click="link($event)">Зарегистрировать чек</button>
      </div>
    </div>
    <div class="rul">
        <img class="big-sale-img" src="/assets/image/2024/rul.png" />
      </div>
      <div class="pila updown" :style="`--delay: ${Math.random() * 2}s;`">
        <img class="big-sale-img" src="/assets/image/2024/pila.png" />
      </div>
    <!-- <div class="purchase-wrap_images">
      <img src="/assets/image/products/bolgarka.png" alt="" class="item-img bolgarka anim-show">
      <img src="/assets/image/products/DCK.png" alt="" class="item-img DCK anim-show">
    </div> -->
  </section>
</template>

<script>
export default {
  name: "SectionComponent1",
    methods: {
      link(event) {
        this.$emit('link',event)
      },
  }
}
</script>

<style lang="scss" scoped>

//1 блок
.purchase-section {
  .big-sale{
    z-index: 4;
  } 
  @media (max-width: 967px) {
    .instr,  .rul, .pila, .shur {
      display: none;
    } 
  }

  .instr {
    img {
      max-width: 100%;
    }
    position: absolute;
    max-width: 19vw;
    left: 5vw;
    top: 10%;
    z-index: 1;
  }
  .rul {
    img {
      max-width: 100%;
    }
    position: absolute;
    max-width: 19vw;
    right: 4vw;
    top: 10%;
    z-index: 1;
  }
  .pila {
    img {
      max-width: 100%;
    }
    position: absolute;
    max-width: 32vw;
    right: 4vw;
    top: 35%;
    z-index: 1;
  }
  .shur {
    img {
      max-width: 100%;
    }
    position: absolute;
    max-width: 19vw;
    left: 9vw;
    top: 35%;
    z-index: 1;
  }
  display: flex;
  justify-content: center;
  overflow: hidden;

  .purchase {
    max-width: 1320px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .big-sale {
        margin: 55px 0 32px 0;
        max-width: 50vw;
        @media (max-width: 767px) {
          max-width: 80vw;
        }
        img.big-sale-img {
            max-width: 100%;
        }
    }

    &-title {
      font: 4.5vw / 100% "Montserrat ExtraBold";
      text-transform: uppercase;
      text-align: center;
      color: black;

      @media (min-width: 769px) and (max-width: 967px) {
        font: 6.5vw / 100% "Montserrat ExtraBold";
      }

    }

    &-text {
      font: 1.5vw / 58px "Montserrat";

      margin-bottom: 56px;
      text-align: center;
      width: 100%;
      color: black;
    }

    &-btns {
      display: flex;
      flex-direction: row;

      .btn-orange {
        color: #fff;  
        margin-right: 30px;
      }
    }
  }

  .purchase-wrap_images {
    position: relative;
  }

  .item-img {
    position: absolute;
    z-index: 1;
  }

  .bolgarka {
    top: 50px;
    right: -250px;
    transform: rotate(-30deg);
  }

  .DCK {
    top: 260px;
    left: -35px;
    transform: rotate(-13deg);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding-top: 10px;
    
    .purchase {
      max-width: unset;
      order: 1;
      text-align: center;

      &-title {
        font: 62px/68px "Montserrat ExtraBold"; 
      }

      &-text {
        margin-bottom: 40px;
        margin-top: 24px;
        font: 30px/24px sans-serif;
        width: 100%;
      }

      &-btns {
        flex-direction: column;

        .btn-orange {
          margin-right: unset;
          margin-bottom: 30px;
        }
      }
    }
    .item-img {
      position: relative;
      z-index: 1;
    }

    .bolgarka {
      left: 55%;
      top: -15px;
      bottom: 0;
      transform: rotate(0deg);
      // min-height: 300px;
      // max-width: 300px;
      width: 60%;
    }

    .DCK {
      max-height: 274px;
      top: 0px;
      right: 50px;
      transform: rotate(45deg);
      max-width: 305px;
      width: 50%;
      position: absolute;
    }
  }
  @media (max-width: 500px) {
    .purchase {
      &-title {
        font: 10vw / 100% "Montserrat ExtraBold";
      }

      &-text {
        font: 20px/24px sans-serif;
      }

      &-btns {
        flex-direction: column;
        margin: 0 5%;
        .btn-orange {
          margin-bottom: 15px;
          color: #fff;          
        }

        .btns {
          font: 15px/18px "Montserrat";
          
        }
      }
    }
  }

  // @media (max-width: 458px){
   
  //   .bolgarka {
  //     left: 40%;
  //     top: -30px;
  //     bottom: 0;
  //     transform: rotate(15deg);
  //   }

  //   .DCK {
  //     max-height: 274px;
  //     min-height: 191px;
  //     top: 0px;
  //     left: 20px;
  //     transform: rotate(30deg);
  //     position: absolute;
  //   }
  // }
  // @media (max-width: 458px) {
  //   .bolgarka {
  //     left: 50%;
  //     top: -10px;
  //     bottom: 0;
  //     transform: rotate(-5deg);
  //     min-height: 200px;
  //     max-width: 200px;
  //     width: 50%;
  //   }
  //   .DCK {
  //     max-height: 200px;
  //     min-height: 191px;
  //     height: 100%;
  //     top: 0px;
  //     left: 20px;
  //     transform: rotate(0deg);
  //     max-width: 280px;
  //     width: 50%;
  //     position: absolute;
  //   }
  // }
  .anim-show {
    opacity: 0; /*Элемент полностью прозрачный (невидимый)*/
    transition: 1s; /*Скорость перехода состояния элемента*/
    animation: show 3s 1; /* Указываем название анимации, её время и количество повторов*/
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    animation-delay: 1s; /* Задержка перед началом */
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.updown {
    animation: updown 2s ease-in-out infinite;
    animation-delay: var(--delay);
}
@keyframes updown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }   
}
</style>