<template>
    <section class="container-xl gift" :style="{ backgroundImage: `url(${background})` }">
      <h1 class="title-section">
        Что можно выиграть?
      </h1>
      <div class="image-container">
        <div class="col right">
          <template v-for="(image, index) in images" :key="index">
            <div :class="['image-box', image.class, { 'shiny-image-container': image.class === 'sertificat' }]"> 
              <img :src="image.src" alt="" class="item" :class="`${image.class} ${image.anim}`" :style="`--delay: ${Math.random() * 2}s;`" />
            </div>
          </template>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        background:'/assets/image/2024/background-mobile-new.png',
        images: [
          {
            src: '/assets/image/2024/1.png',
            class: 'sertificat',
            anim: 'shiny'
          },
          {
            src: '/assets/image/2024/2.png',
            class: 'money',
            anim: 'wobble'
          },
          {
            src: '/assets/image/2024/3.png',
            class: 'whatch',
            anim: 'updown'
          },
          {
            src: '/assets/image/2024/4.png',
            class: 'headphones ',
            anim: 'updown'
          },
          {
            src: '/assets/image/2024/6.png',
            class: 'tools',
            anim: 'updown'
          },
          {
            src: '/assets/image/2024/5.png',
            class: 'powerbank',
            anim: 'wobble'
          },
  
        ]       
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .gift {
    background: white;
    border-radius: 60px;
    padding-top: 8vw;
    background-size: 80vw;
    background-position-y: 30vw;
    background-position-x: 10vw;
    background-repeat: no-repeat;
    margin: 96px 5% 0;
    width: 90%;

    .title-section {
      font: 700 8vw/7vw 'Montserrat ExtraBold';
      text-transform: uppercase;
      text-align: center;
      color: black;
    }
  }
  
  .image-container {
    position: relative;

    width: 100%;
    height: auto;

    /* Добавляет пространство между изображениями */
    .col {
      height: 270vw;
      width: 100%;
 
  
      .image-box {
        // flex: 1 1 calc(50% - 10px);
        /* Две колонки с учетом пространства между изображениями */
        position: relative;
        .money {
          right: 32%;
          bottom: 43vw;
          position: relative;
          width: 99%;
        }
        &.sertificat {
          width: 60%;
          left: 32vw;
          top: 43vw;
          position: relative;

          .sertificat {
            max-width: 100%;
          }

        }
        .headphones {
          right: 15%;
          bottom: 76vw;
          position: relative;
          width: 60%;
        }
        .whatch {
          left: 55%;
          bottom: 25vw;
          position: relative;
          width: 55%;
         
        }
        .powerbank {
          left: 50%;
          bottom: 105vw;
          position: relative;
          width: 60%;
        }
        .tools {
          right: 10%;
          bottom: 35vw;
          position: relative;
          width: 60%;
        }
      }
    }
  }
  
  @keyframes updown {
      0%, 100% {
          transform: translateY(0);
      }
      50% {
          transform: translateY(-10px);
      }   
  }
  
  @keyframes wobble {
      0%, 100% {
          transform: translateY(0) rotate(0deg);
      }
      50% {
          transform: translateY(-10px) rotate(-2deg);
      }   
  }
  
  .updown {
      animation: updown 2s ease-in-out infinite;
      animation-delay: var(--delay);
  }
  
  .wobble {
      animation: wobble 2s ease-in-out infinite;
      animation-delay: var(--delay);
  }
  
  
  .shiny-image-container {
      position: relative;
      overflow: hidden; /* Чтобы блеск не выходил за границы контейнера */
  }
  
  // .shiny {
  //     display: block;
  // }
  
  .shiny-image-container::before {
      content: '';
      position: absolute;
      top: 0;
      left: -150%; /* Начинает за пределами изображения */
      width: 25%;
      height: 100%;
      // background: linear-gradient(93deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(93deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(255, 255, 255, 0) 100%);
      transform: skewX(-25deg);
      pointer-events: none; /* Чтобы блеск не мешал взаимодействию с изображением */
      animation: shine 3s ease-in-out infinite;
      z-index: 100;
  }
  
  @keyframes shine {
      0% {
          left: -150%;
      }
      100% {
          left: 150%;
      }
  }
  
  </style>
  