<template>
  <footer>
    <div class="container-xl footer">
      <div class="logo shadow-rad">
        <img src="/assets/image/header/prize.png" alt="">
      </div>
      <div class="footer-menu_links shadow-rad_blue">
        <ul>
          <li class="li-link">
            <a href="policy/Rules24.pdf" download class="link">
              Правила акции
            </a>
          </li>
          <li class="li-link">
            <a href="policy/Privacy.pdf" download class="link">
              Политика конфиденциальности
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style lang="scss" scoped>
footer {
  z-index: 10;
  background: #434343;
  border-radius: 48px 48px 0px 0px;
  padding: 48px 0;

  @media (max-width: 768px) {
    height: 280px;
  }

  @media(max-width: 420px) {
      border-radius: 20px 20px 0px 0px;
      padding-left: initial !important;
    }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 1200px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    .logo {

      @media(max-width: 410px) {
        margin-bottom: 80px !important;
      }

      img {
        position: relative;
        max-width: 351px;
        width: 100%;

        @media(max-width: 480px) {
          width: 80%;
        }
      }
    }

    .footer-menu_links {
      position: relative;


      ul {
        list-style: none;
        padding-left: 0px;

        li {
          margin-top: 5px;

          .link {
            font: 18px/20px "Montserrat";
            color: #fff;
            font-style: normal;
            line-height: 29px;
            text-decoration: none;
          }

          @media(max-width: 480px) {
            .link {
              font: 16px / 7px "Montserrat" !important;
            }
          }

          @media (max-width: 390px) {
            .link {
              font: 14px/17px 'Montserrat' !important;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .logo {
        margin-bottom: 44px;
      }

      text-align: center;
    }
  }

  .shadow-rad:before {
    width: 10px;
    height: 10px;
    opacity: 0.5;
    top: 220px;

    @media (max-width: 400px) {
      opacity: 0;
    }
  }
}
</style>