<template>
  <section class="section" id="section3">
    <div class="wrap-title">
      <div class="title">
        <h1 class="title-section">Как участвовать?</h1>
        <div class="text_description subtext">
          Больше зарегистрированных чеков — больше шансов получить подарки!
        </div>
      </div>
      <div class="img-title shadow-rad_blue">
        <img src="/assets/image/products/bublick.svg" alt="">
      </div>
    </div>
    <div class="wrap-items shadow-rad">
      <div class="item">
        <div class="item-text">
          <span class="number">
            <img src="../../public/assets/image/products/01.png" class="num_img-1" alt="">
          </span>
          <div class="text_description first">
            В период с <b>01.07.2024 по 28.07.2024</b> <br> покупайте товары в магазинах 100Дел <br> и Стройка на сумму
            от 3000
            рублей.
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-text">
          <span class="number">
            <img src="../../public/assets/image/products/02.png" class="num_img-2" alt="">
          </span>
          <div class="text_description second">
            Зарегистрируйте Ваш чек на сайте <br> stroika-100del.ru и примите участие <br> в одном из 4х еженедельных
            розыгрышей.
          </div>
        </div>
      </div>
    </div>
    <div class="item item-big">
      <div class="item-text big_item-text">
        <span class="number big_number">
          <img src="../../public/assets/image/products/03.png" class="num_img-big" alt="">
        </span>
        <div class="text_description third">
          29.07.2024 года все чеки, зарегистрированные в период акции, примут участие <b>В ГРАНДФИНАЛЕ</b>, где мы
          разыграем призы от партнеров.
        </div>
      </div>
      <div class="item-img">

        <div class="wrap_img wrap-chain">
          <div class="img img-dsk img-1">
            <img src="/assets/image/products/chainsaw.png" class="img-inner_1" alt="dsk">
          </div>
          <div class="text-img_description drel">
            Бензопила<br> FUBAG <br> FPS 56
          </div>
        </div>
        <div class="wrap_img wrap-generator">
          <div class="img img-bolgarka img-2">
            <img src="/assets/image/products/generator.png" class="img-inner_2" alt="">
          </div>
          <div class="text-img_description bolgarka">
            Генератор инверторный FUBAG TI 1000
          </div>
        </div>
        <div class="wrap_img wrap-drel">
          <div class="img img-pila img-3">
            <img src="/assets/image/products/drel.png" class="img-inner_3" alt="">
          </div>
          <div class="text-img_description pila">
            Дрель-шуруповерт аккум DCK <br> KDPL04-8 Impact
          </div>
        </div>
        <div class="wrap_img wrap-pusk">
          <div class="img img-pila img-4">
            <img src="/assets/image/products/pusk.png" class="img-inner_4" alt="">
          </div>
          <div class="text-img_description pila">
            Устройство пусковое FUBAG DRIVE 600
          </div>
        </div>
        <div class="wrap_img wrap-shlif">
          <div class="img img-pila img-5">
            <img src="/assets/image/products/shlif.png" class="img-inner_5" alt="">
          </div>
          <div class="text-img_description pila">
            Машина шлифовальная аккумуляторная DCK
          </div>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button class="btn-orange btns" data-href="#form" @click="link($event)">Зарегистрировать чек</button>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionComponent3",
  methods: {
    link(event) {
      this.$emit('link', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-items {
  @media(max-width: 768px) {
    margin-top: 50px !important;
  }

  @media(max-width: 520px) {
    margin-top: 20px !important;
  }

  .item {
    .text_description {
      font-family: 'Montserrat' !important;
    }
  }
}

.item {
  @media(max-width: 480px) {
    padding-left: 30px !important;
  }

  .text_description {
    font: 400 24px/29px 'Montserrat';
    width: 110%;
  }
}

.subtext {
  @media(max-width: 978px) {
    font: 400 34px / 120% "Montserrat" !important;
    color: #000;
    width: 85% !important;
  }

  @media(max-width: 768px) {
    font: 400 26px/140% 'Montserrat' !important;
    text-align: left !important;
    padding-left: 10px !important;
    padding-top: 20px;
    width: 90% !important;
  }

  @media(max-width: 700px) {
    font: 400 26px/140% 'Montserrat' !important;
    width: 90% !important;
  }
}

.num_img-1 {
  @media(max-width: 768px) {
    width: 20%;
    margin-bottom: 30px;
  }

  @media(max-width: 544px) {
    width: 80px;
  }

  @media(max-width: 480px) {
    width: 60px;
  }
}

.num_img-2 {
  @media(max-width: 768px) {
    width: 22%;
    margin-bottom: 30px;
  }

  @media(max-width: 544px) {
    width: 95px;
  }

  @media(max-width: 480px) {
    width: 70px;
  }
}

.num_img-big {
  @media(max-width: 768px) {
    width: 95px;
    margin-bottom: 30px;
  }

  @media(max-width: 480px) {
    width: 70px;
  }
}



.third {
  width: 70% !important;

  @media(max-width: 976px) {
    width: 65% !important;
    font: 400 20px / 140% "Montserrat" !important;
  }
}

.title {
  .title-section {
    @media(max-width: 768px) {
      font: 700 8vw/110% "Montserrat ExtraBold";
    }

    @media(max-width: 590px) {
      font: 700 28px / 110% "Montserrat ExtraBold";
      width: 80%;
      margin-bottom: 30px
    }

    @media(max-width: 480px) {
      font: 700 34px / 110% "Montserrat ExtraBold";
      width: 90%;
      padding-left: 0px !important;
    }

    @media(max-width: 410px) {
      font: 700 32px/34.6px "Montserrat ExtraBold";
    }
  }

  .text_description {
    font: 400 30px / 120% "Montserrat";

    @media(max-width: 1200px) {
      margin-top: 50px !important;
      font: 400 30px / 120% "Montserrat";
      width: 500px;
      padding-left: 30px;
    }

    @media(max-width: 968px) {
      width: 720px !important;
      font: 400 28px / 120% "Montserrat" !important;
    }

    @media(max-width: 768px) {
      font: 700 8vw/60.6px "Montserrat ExtraBold";
      width: 600px !important;
    }

    @media(max-width: 620px) {
      width: 560px !important;
      font: 400 24px / 120% "Montserrat" !important;
    }

    @media(max-width: 544px) {
      width: 484px !important;
      font: 400 20px / 120% "Montserrat" !important;
    }

    @media(max-width: 480px) {
      padding-left: 0px !important;
      margin-left: 0px !important;
    }

    @media(max-width: 460px) {
      width: 425px !important;
      font: 400 18px / 130% "Montserrat" !important;
    }

    @media(max-width: 380px) {
      font: 400 17px / 130% "Montserrat" !important;
      width: 400px !important;
    }
  }
}

.img {
  @media(max-width: 1400px) {
    width: 210px !important;
  }

  @media(max-width: 1100px) {
    width: 180px !important;
  }
}

.img-title {
  @media(max-width: 1870px) {
    display: initial !important;
  }

  @media(max-width: 1476px) {
    max-width: 115px !important;
  }

  @media(max-width: 1400px) {
    right: 110px !important;
    top: 190px !important;
  }

  @media(max-width: 1200px) {
    display: none !important;
  }
}

.wrap_img {
  position: relative;

  @media(max-width: 968px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.big_item-text {
  display: flex !important;
  flex-wrap: nowrap;
  padding-top: 32px;

  @media(max-width: 480px) {
    padding-top: 12px;
  }
}

.text-img_description {
  margin-top: 406px;
  font: 200 16px/110% 'Montserrat';
  color: #fff;
  width: 176px;
  text-align: center;
  padding-bottom: 50px;

  @media(max-width: 1400px) {
    width: 176px;
  }

  @media(max-width: 1100px) {
    font: 200 16px / 130% "Montserrat";
    margin-top: 250px;
  }

  @media(max-width: 968px) {
    font: 700 22px / 130% "Montserrat";
    margin-top: 250px;
    width: 236px;
    text-align: left;
    position: relative !important;
    left: 10%;
    margin-top: 100px;
  }

  @media(max-width: 520px) {
    font: 700 18px / 130% "Montserrat" !important;
    margin-top: 250px;
    width: 190px;
    text-align: left;
    position: relative !important;
    left: 10%;
    margin-top: 100px;
  }

  @media(max-width: 460px) {
    font: 700 16px / 135% "Montserrat" !important;
    width: 250px;
    text-align: left;
    position: relative !important;
    left: 2%;
    margin-top: 80px;
  }
}

.img-1 {
  position: relative;

  .img-inner_1 {
    position: absolute;
    top: 25px;
    left: -100px;
    width: 170%;

    @media(max-width: 1400px) {
      top: 70px;
      left: -90px;
      width: 160%;
    }

    @media(max-width: 1100px) {
      top: 0px;
      left: -50px;
      width: 150%;
    }

    @media(max-width: 968px) {
      left: -40%;
      width: 195%;
      top: -38px;
    }

    @media(max-width: 700px) {
      left: -55%;
    }

    @media(max-width: 520px) {
      left: -30%;
      width: 165%;
      top: -25px;
    }

    @media(max-width: 460px) {
      left: -42%;
      width: 226px;
      top: -15px;
    }

    @media(max-width: 420px) {
      left: -43%;
      width: 210px;
      top: -2px;
    }

    @media(max-width: 400px) {
      left: -56%;
      width: 210px;
      top: -2px;
    }
  }
}

.img-2 {
  position: relative;

  .img-inner_2 {
    position: absolute;
    top: 140px;
    left: -20px;
    width: 100%;

    @media(max-width: 1400px) {
      top: 155px;
      left: -15px;
      width: 102%;
    }

    @media(max-width: 1100px) {
      top: 65px;
      left: 15px;
      width: 90%;
    }

    @media(max-width: 968px) {
      left: 20%;
      width: 110%;
      top: 40px;
    }

    @media(max-width: 700px) {
      left: 5%;
    }

    @media(max-width: 520px) {
      left: 13%;
      top: 38px;
      width: 100%;
    }

    @media(max-width: 460px) {
      left: -2%;
      top: 32px;
      width: 139px;
    }

    @media(max-width:420px) {
      left: -5%;
      top: 37px;
      width: 130px;
    }

    @media(max-width: 400px) {
      left: -20%;
      top: 37px;
      width: 130px;
    }
  }
}

.img-3 {
  position: relative;

  .img-inner_3 {
    position: absolute;
    top: 110px;
    left: 0px;
    width: 86%;

    @media(max-width: 1400px) {
      top: 120px;
      left: 0px;
      width: 90%;
    }

    @media(max-width: 1100px) {
      top: 40px;
      left: 20px;
      width: 81%;
    }

    @media(max-width: 968px) {
      left: 25%;
      width: 100%;
      top: 22px;
    }

    @media(max-width: 700px) {
      left: 10%;
    }

    @media(max-width: 520px) {
      left: 23%;
      width: 85%;
      top: 30px;
    }

    @media(max-width: 460px) {
      left: 0%;
      width: 128px;
      top: 19px;
    }

    @media(max-width: 420px) {
      left: 1%;
      width: 120px;
      top: 25px;
    }

    @media(max-width: 400px) {
      left: -20%;
      width: 120px;
      top: 25px;
    }
  }
}

.img-4 {
  position: relative;

  .img-inner_4 {
    position: absolute;
    top: 6px;
    left: -150px;
    width: 204%;

    @media(max-width: 1400px) {
      top: 60px;
      left: -90px;
      width: 190%;
    }

    @media(max-width: 1100px) {
      top: 0px;
      left: -60px;
      width: 170%;
    }

    @media(max-width: 968px) {
      left: -21%;
      width: 200%;
      top: -35px;
    }

    @media(max-width: 700px) {
      left: -36%;
    }

    @media(max-width: 520px) {
      left: -16%;
      width: 170%;
      top: -20px;
    }

    @media(max-width: 460px) {
      left: -45%;
      width: 250px;
      top: -20px;
    }

    @media(max-width: 400px) {
      left: -66%;
      width: 240px;
      top: -12px;
    }
  }
}

.img-5 {
  position: relative;

  .img-inner_5 {
    position: absolute;
    top: 110px;
    left: -50px;
    width: 135%;

    @media(max-width: 1400px) {
      top: 127px;
      left: -45px;
      width: 138%;
    }

    @media(max-width: 1100px) {
      top: 45px;
      left: -20px;
      width: 125%
    }

    @media(max-width: 968px) {
      left: -1%;
      width: 148%;
      top: 18px;
    }

    @media(max-width: 700px) {
      left: -16%;
    }

    @media(max-width: 520px) {
      left: 0%;
      width: 130%;
      top: 25px;
    }

    @media(max-width: 460px) {
      left: -28%;
      width: 200px;
      top: 19px;
    }

    @media(max-width: 420px) {
      left: -22%;
      width: 180px;
      top: 30px;
    }

    @media(max-width: 400px) {
      left: -42%;
        width: 185px;
        top: 34px;
    }
  }
}

.third {
  color: #fff;
  padding-top: 5px;

  @media(max-width: 1400px) {
    width: 75%;
  }

  @media(max-width: 768px) {
    width: 95% !important;
  }
}

.item {
  border-radius: 30px !important;

  @media(max-width: 768px) {
    padding: 22px 48px;
    margin-top: 20px;
  }

  .item-text {
    gap: 50px;
    justify-content: start !important;

    @media(max-width: 768px) {
      display: block !important;
    }

    .big_number {
      padding-left: 42px;

      @media(max-width: 768px) {
        padding-left: initial !important;
      }
    }

    .text_description {
      @media(max-width: 768px) {
        font: 400 20px/140% 'Montserrat' !important;
      }

      @media(max-width: 544px) {
        font: 400 18px/150% 'Montserrat' !important;
        width: 104% !important;
      }

      @media(max-width: 460px) {
        font: 400 16px/150% 'Montserrat' !important;
      }

      @media(max-width: 410px) {
        font: 400 15px/150% 'Montserrat' !important;
      }
      @media(max-width: 395px) {
        font: 400 14px/150% 'Montserrat' !important;
      }
      @media(max-width: 370px) {
        font: 400 13px/150% 'Montserrat' !important;
      }
    }
  }

  .item-img {
    align-items: end;
    justify-content: center;
    padding-left: 50px;

    @media(max-width: 1400px) {
      padding-left: 25px;
    }

    @media(max-width: 1100px) {
      padding-left: 0px;
    }
  }
}

.item-big {
  max-width: 100%;
  background-image: url(../../public/assets/image/gradient.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  margin-top: 40px;

  @media(max-width: 968px) {
    background-image: url(../../public/assets/image/gradient_adaptive.jpg);
  }

  @media(max-width: 520px) {
    background-image: url(../../public/assets/image/gradient_mobile.jpg);
  }

  @media(max-width: 420px) {
    background-image: url(../../public/assets/image/gradient_mobile-mini.jpg);
  }

  .item-img {
    display: flex;

    @media(max-width: 968px) {
      display: block;
    }

    .wrap-img {
      max-width: 240px;
    }

    .img {
      width: 247px;

      .img-inner {
        width: 247px;
      }
    }
  }
}

.btn-orange {
  height: 90px;
  color: #fff;
  font: 700 24px/110% 'Montserrat' !important;

  @media(max-width: 520px) {
    height: 80px;
    font: 700 20px / 110% "Montserrat" !important;
  }

  @media(max-width: 460px) {
    height: 78px !important;
    font: 700 18px / 110% "Montserrat" !important;
  }
  @media(max-width: 400px) {
    height: 70px !important;
    font: 700 18px / 110% "Montserrat" !important;
  }
}

.section {
  margin-top: 104px;

  .wrap-title {
    display: flex;
    position: relative;

    .title {
      .title-section {
        font: 700 70px/105.6px "Montserrat ExtraBold";
        text-transform: uppercase;
        color: #000;

        @media(max-width: 1200px) {
          padding-left: 30px;
          font: 700 5vw/105.6px "Montserrat ExtraBold";
          position: relative;
          top: 50px;
        }

        @media(max-width: 768px) {
          font: 700 8vw/110% "Montserrat ExtraBold";
        }

        @media(max-width: 410px) {
          font: 700 32px / 34.6px "Montserrat ExtraBold";
        }

        z-index: 2;
      }

      .text_description {
        z-index: 2;
        margin-top: 22px;
        font: 400 40px/120% 'Montserrat';
        color: #000;
        width: 968px;
      }

      @media (max-width: 768px) {
        .text_description {
          width: 100%;
          text-align: center;
          font: 20px/38px 'Stolz Light';
          margin-left: 20px;
        }
      }
    }

    .img-title {
      position: absolute;
      z-index: 3;
      right: 170px;
      top: 210px;
      max-width: 110px;

      @media (max-width: 1870px) {
        display: none;
      }

      img {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 247px;
      }

      @media (max-width: 1476px) {
        max-width: 550px;

        &.shadow-rad_blue:before {
          top: 70px;
        }
      }
    }
  }

  .wrap-items {
    display: flex;
    flex-wrap: wrap;
    margin: -24px;
    margin-top: 88px;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .item {
      display: flex;
      flex: 1 0 46% !important;
      height: 310px;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      background-color: #fff !important;

      .item-img {
        display: flex;
        flex-wrap: nowrap;
        width: 247px;


        @media (max-width: 1700px) {
          column-gap: 40px;
        }

        @media (max-width: 1639px) {
          column-gap: 25px;

          .img-cabel {
            margin-left: -66px;
          }
        }

        @media (max-width: 1360px) {
          column-gap: 30px;
        }

        @media (max-width: 1300px) {
          column-gap: 0px;
        }


        .img-line {
          max-width: 300px;
          min-width: 250px;
          width: 100%;
        }

        .wrap_img {
          max-width: 200px;




          &:nth-child(1) {
            max-width: unset;
          }

          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .text-img_description {
            font: 16px/19px 'Stolz Light';
            margin-top: 20px;
          }

          .drel {
            width: 259px;
          }

          .bolgarka {
            width: 277px;
          }

          .pila {
            margin-bottom: 19px;
            width: 277px;
          }

          @media (max-width: 1600px) {


            .drel {
              width: 200px;
            }

            .bolgarka {
              width: 250px;
            }

            .pila {
              margin-bottom: 19px;
              width: 250px;
            }
          }

          @media (max-width: 1380px) {

            .img-dsk {
              margin-left: 40px;
            }

            .drel {
              width: 150px;
              margin-left: 40px;
            }

            .bolgarka {
              width: 150px;
            }

            .pila {
              margin-bottom: 19px;
              width: 130px;
            }
          }

          @media (max-width: 1230px) {
            .img-dsk {
              margin-left: 0px;
            }

            .drel {
              width: 150px;
              margin-left: 0px;
            }

          }
        }

        .wrap-drel {
          margin-right: 60px;
        }

        .wrap-bolgarka {
          margin-right: 60px;
        }

        img,
        .img {
          width: 100%;
        }

        .img-dsk {
          transform: rotate(-15deg);
          width: 190px;
        }

        .img-bolgarka {
          transform: rotate(-30deg);
          margin-bottom: 40px;
        }

        @media (max-width: 1500px) {
          .wrap-drel {
            margin-right: 0px;
          }

          .wrap-bolgarka {
            margin-right: 0px;
          }
        }

        @media (max-width: 1500px) {
          .img-dsk {
            width: 150px;
          }

          .img-bolgarka {
            width: 180px;
          }

          .img-pila {
            width: 180px;
          }
        }
      }

      .item-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .price {
        width: 340px;
        height: 121px;
        margin-top: 32px;
        font-size: 42px;
        line-height: 50px;
      }

      .price-lider {
        color: #FFCD1E;

      }

      .money {
        font-size: 64px;
        line-height: 64px;
        font-weight: 500px;
      }

      .img-price {
        display: flex;
      }

      .img-cabel {
        width: 150px;
      }

      .img-bag {
        margin-left: -30px;
        margin-top: -20px;
      }

      @media (max-width: 1300px) {
        .price {
          margin-right: 40px;
        }

        .img-cabel {
          margin-left: -66px;
        }

        @media (max-width: 1240px) {
          .img-cabel {
            margin-bottom: 15px;
          }
        }

      }

      flex: 0 0 35%;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(4px);
      border-radius: 48px;
      padding: 32px 48px;
      margin: 24px;

      @media (max-width: 1476px) {
        flex: 1 1 0;

        &:nth-child(3) {
          height: max-content;
        }
      }

      @media (max-width: 768px) {
        border-radius: 16px;
      }

      .number {
        font: 64px/77px 'Stolz';
      }

      .text_description {
        font: 24px/29px 'Stolz Light';
      }

      &:nth-last-child(1) {
        flex: 1 1 100%;
      }

      @media (max-width: 1146px) {
        .item-img {
          justify-content: space-around;

        }

        .price {
          display: none;
        }


        &:nth-child(3) {
          flex-direction: column;

          .wrap_img {
            &:nth-child(1) {
              display: none;
            }

            .drel {
              width: 300px;
            }

            .bolgarka {
              width: 300px;
            }

            .pila {
              width: 300px;
            }
          }

          .img-cabel {
            display: none;
          }

          .img-bag {
            display: none;
          }

          .money {
            font: 29px/29px 'Stolz Light';
          }

          .price-lider {

            font: 29px/29px 'Stolz Light';
          }
        }
      }

      @media (max-width: 930px) {
        .item-img {
          justify-content: space-around;
          column-gap: 40px;
        }



        &:nth-child(3) {
          flex-direction: column;

          .wrap_img {
            &:nth-child(1) {
              display: none;
            }

            .drel {
              width: 200px;
            }

            .bolgarka {
              width: 240px;
            }

            .pila {
              width: 250px;
            }
          }

        }


      }

      @media (max-width: 768px) {
        .item-img {
          flex-direction: column;
          flex: 1;

          .wrap_img {
            flex-direction: row;
            margin: unset;
            max-width: unset;
            align-items: flex-end;
            justify-content: space-between;

            .img {
              margin-top: 20px;
              max-width: 100px;
              width: 100%;
              margin-right: 10px;
            }

            .text-img_description {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 500px) {


        .item-text {
          .number {
            font: 24px/29px 'Stolz';
          }

          .text_description {
            font: 14px/17px 'Stolz Light';
          }
        }

        .item-img .wrap_img {
          margin-top: 30px;

          .img {
            display: flex;
            justify-content: center;
          }

          img {
            width: 100px;
          }

          .text-img_description {
            font: 14px/17px 'Stolz Light';
          }
        }
      }

      &:nth-child(3) {
        .item-text {
          flex: 0 0 31%;
        }
      }
    }
  }

  .wrap-button {
    margin: 72px 0 0;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      margin: 54px 0 0;
    }

    .btns {
      position: relative;
      z-index: 1;
      font: 24px/29px 'Stolz Light';

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (max-width: 448px) {
        font: 15px/18px 'Stolz Light';
      }
    }
  }

  .shadow-rad {
    overflow: unset;

    &:before {
      width: 280px;
      height: 280px;
      top: 400px;
    }
  }
}
</style>