<template>
  <section class="section container-xl">
    <div class="title">
      <h1 class="title-section">
        до окончания розыгрыша осталось
      </h1>
      <div class="img-section shadow-rad">
        <img src="/assets/image/products/colonka2.png" alt="">
      </div>
    </div>
    <div class='timer'>
      <div class="item">
        <div class="time">
      <span>
        {{ Math.floor(displayDays / 10) }}
      </span>
          <span>
        {{ displayDays % 10 }}
      </span>
        </div>
        <div class="timer-name">
          дней
        </div>
      </div>
      <div class="item">
        <div class="time">
      <span>
        {{ Math.floor(displayHours / 10) }}
      </span>
          <span>
        {{ displayHours % 10 }}
      </span>
        </div>
        <div class="timer-name">
          часов
        </div>
      </div>
      <div class="item">
        <div class="time">
      <span>
        {{ Math.floor(displayMinutes / 10) }}
      </span>
          <span>
        {{ displayMinutes % 10 }}
      </span>
        </div>
        <div class="timer-name">
          минут
        </div>
      </div>
      <div class="item">
        <div class="time">
      <span>
  {{ Math.floor(displaySeconds / 10) }}
      </span>
          <span>
  {{ displaySeconds % 10 }}
      </span>
        </div>
        <div class="timer-name">
          секунд
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "TimeComponent",
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    }
  },
  mounted() {
    this.showRemaining();
  },
  methods: {
    formatNumb(num) {
      return num < 10 ? `0${num}` : num;
    },
    showRemaining() {
      // const timer = setInterval(() => {
      //   const now = new Date();
      //   const end = new Date(2024, 6, 29, 11, 35, 0);
      //   // const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);

      //   if(now.getTime() >= end.getTime()){
      //     end.setDate(end.getDate()+7);
      //   }
      //   const distance = end.getTime() - now.getTime();
      //   if (end.getTime() - now.getTime() < 0) {
      //     clearInterval(timer);
      //     return;
      //   }

      //   const days = Math.floor((distance / this._days));
      //   const hours = Math.floor((distance % this._days) / this._hours);
      //   const minutes = Math.floor((distance % this._hours) / this._minutes);
      //   const seconds = Math.floor((distance % this._minutes) / this._seconds);

      //   this.displayMinutes = this.formatNumb(minutes);
      //   this.displaySeconds = this.formatNumb(seconds);
      //   this.displayHours = this.formatNumb(hours);
      //   this.displayDays = this.formatNumb(days);
      //   this.loaded = true;
      // }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  margin-top: 204px;
  padding-left: 40px;
  background-image: url(../../public/assets/image/timer_background.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 60px;
  width: 97%;

  @media(max-width: 1400px) {
    margin-top: 104px;
  }

  @media(max-width: 530px) {
    padding-left: 15px;
    border-radius: 30px;
    margin-top: 50px;
  }

  .title {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
  }
  .shadow-rad {
      margin-top: 140px;
      margin-right: 50px;
    }

  .title-section {
    position: relative;
    left: 10px;
    font-style: normal;
    font-weight: 800;
    font-size:76px;
    line-height: 110%;
    text-transform: uppercase;
    color: #fff;
    z-index: 3;
    margin-top: 70px;
    @media(max-width: 1200px) {
      width: 90%;
    }
    @media(max-width: 968px) {
      font-size: 60px;
    }
    @media (max-width: 768px) {
      max-width: 80%;
      font-size: 48px;
    }
    @media(max-width: 700px) {
        max-width: 80%;
        font-size: 40px;
        margin-top: 50px;
    }

    @media(max-width: 650px) {
        max-width: 72%;
        font-size: 34px;
        margin-top: 30px
    }
    @media(max-width: 487px) {
      font-size: 28px;
    }
    @media(max-width: 408px) {
      font-size: 25px;
    }
  }

  .img-section {
    position: relative;
    z-index: 1;
    overflow: unset;

    img {
      max-width: 475px;
      position: absolute;
      right: -130px;
      top: -400px;

      @media(max-width: 1400px) {
      max-width: 510px;
      right: -150px;
      top: -260px;
      }

      @media(max-width: 1200px) {
        right: -200px;
      }

      @media(max-width: 768px) {
        top: 10px;
        max-width: 400px;
        right: 0px;
        left: 350px;
      }

      @media(max-width: 700px) {
        top: 8px;
        max-width: 343px;
        right: 0px;
        left: 330px;
      }

      @media(max-width: 650px) {
        top: -29px;
        max-width: 343px;
        right: 0px;
        left: 105%;
      }
      @media(max-width: 550px) {
        top: 23px;
        max-width: 256px;
        right: 0px;
        left: 117%;
      }
      @media(max-width: 487px) {
        top: 15px;
        right: 0px;
        left: 100%;
      }
      @media(max-width: 408px) {
        top: -28px;
        right: 0px;
        left: 111%;
        max-width: 208px;
      }
      @media(max-width: 408px) {
        top: -21px;
        right: 0px;
        left: 115%;
        max-width: 186px;
      }
    }
    
    &.shadow-rad:before {
      width: 150px;
      height: 150px;
      top: 140px;
      left: 150px;
    }

    @media (max-width: 768px) {
      position: absolute;
      top: -240px;
      width: 50%;
    }
    @media (max-width: 408px){
      top: -160px;
    }
  }

  .timer {
    position: relative;
    right: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;
    margin-top: 20px;
    flex-wrap: wrap;

    @media(max-width: 968px) {
      margin-top: 50px
    }

    @media (max-width: 768px) {
      margin: 0 -5px;
    }

    @media(max-width: 530px) {
    padding-left: 15px;
  }
    .item {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media(max-width: 550px) {
        margin: 25px 5px 0 !important;
      }

      .time {
        display: flex;
        flex-wrap: nowrap;
      }

      span {
        flex: 0 0 35%;
        background: #fff;
        backdrop-filter: blur(4px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 22px;
        display: block;
        font: 96px/105px 'Montserrat ExtraBold';
        color: #000;
        font-weight: 800;
        text-align: center;
        padding: 27px 24px;

        @media(max-width: 1200px) {
          padding: 27px 20px;
          border-radius: 12px;
        }

        @media(max-width: 968px) {
          padding: 12px 16px;
          font: 68px/105px 'Montserrat ExtraBold';
          border-radius: 10px;
        }

        @media(max-width: 768px) {
          min-width: 70px;
          font: 700 46px / 46px "Montserrat ExtraBold";
          padding: 28px 5px;
          border-radius: 9px;
        }

        @media(max-width: 700px) {
          padding: 28px 17px;
          min-width: initial !important;
          border-radius: 9px;
        }

        @media(max-width: 650px) {
          padding: 17px 10px;
          min-width: initial !important;
          border-radius: 7px;
        }

        @media(max-width: 530px) {
          padding: 13px 8px;
          min-width: initial !important;
          border-radius: 6px;
        }

        @media(max-width: 487px) {
          padding: 8px 8px;
          font: 700 32px / 46px "Montserrat ExtraBold";
          border-radius: 6px;
        }

        @media(max-width: 408px) {
          padding: 8px 9px;
          font: 700 26px / 38px "Montserrat ExtraBold";
          border-radius: 5px;
          
        }

        @media(max-width: 408px) {
          padding: 8px 9px;
          font: 700 26px / 38px "Montserrat ExtraBold";
          border-radius: 5px;
        }

        @media(max-width: 382px) {
          padding: 4px 8px;
          font: 700 25px / 38px "Montserrat ExtraBold";
        }

        &:nth-child(1) {
          margin-right: 5px;
          @media (max-width: 768px) {
            margin-right: 4px;
          }
          @media(max-width: 408px) {
            margin-right: 3px;
          }
        }
      }

      .timer-name {
        margin-top: 10px;
        margin-bottom: 70px;
        flex: 0 0 100%;
        text-align: center;
        font: 48px/53px "Montserrat";
        color: #fff;
        @media(max-width: 968px) {
          font-size: 38px;
          margin-bottom: 50px;
        }
        @media (max-width: 768px) {
          font: 22px/110% 'Montserrat';
        }
        @media(max-width: 550px) {
          margin-bottom: 30px;
          font: 18px / 110% "Montserrat" !important;
        }
        @media(max-width: 408px) {
          margin-bottom: 22px;
          font: 14px / 110% "Montserrat" !important;
        }
      }
      @media (max-width: 768px) {
        margin: 42px 5px 0;
      }
    }
  }
}
</style>