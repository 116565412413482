<template>
  <header class="header">
    <div class="head desctop">
      <div class="brands-logo header-item">
        <div class="wrap wrap_stroika">
          <img src="/assets/image/header/container_brands2.png" alt="" class="brand-logo stroika desctop">
          <img src="/assets/image/header/container_brands3.png" alt="" class="brand-logo stroika mobile">
        </div>

      </div>
      <div class="header-item phone">
        <a href="tel:88007770616" class="phone-link">8 800 777 06 16</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style lang="scss" scoped>

.header .header-item.brands-logo {
  height: 6vh;
}
.brands-logo.header-item .wrap img {
  max-height: 100%;
}
.header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  .head{
    display: flex;
    flex: 1 0 75%;
    padding-top: 35px;
    @media (max-width: 1068px){
      padding-top: 15px;
    }
    .wrap{
      margin: 0 10px;
      .desctop {
        @media (max-width: 768px){
          display: none;
        }
      }
      .mobile {
        @media (min-width:769px){
          width: 80%;
          display: none;
        }
      }
    }
  }
  .header-item{
    @media (min-width: 768px){
      display: flex;
    }
    
    &.brands-logo{
      gap:20px;
      flex: 1;
    }
  }
  
  .logo {
    flex: 1 0 24%;
    max-width: 337px;
    margin-right: 7.5px;

    img {
      width: 100%;
    }
  }

  .phone {


    a {
      font: 36px/43px "Montserrat";
      @media (min-width: 769px) and (max-width: 1008px) {
        display: flex;
        align-content: center;
        align-items: center;
        font: 23px/33px "Montserrat";
      }
      @media (max-width: 967px) {
        // font: 23px/33px "Montserrat";
        display: none;
      }
      // @media (max-width: 447px) {
      //   font: 15px/33px "Montserrat";
      // }
      // @media (max-width: 380px) {
      //   font: 13px/15.6px "Montserrat";
      // }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;

    .logo {
      flex: auto;
      max-width: unset ;
      margin-right: unset;

      img {
        width: 100%;
      }
    }
    .head{
      align-items: center
    }
  }
  @media (max-width: 547px) {
    .wrap{
      margin: 0 5px;
    }
  }
}

</style>