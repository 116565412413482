import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Maska from 'maska'
// import { createYmaps } from 'vue-yandex-maps';
const app = createApp(App);

app.config.globalProperties.$selectedSurfaces = ["test"];
// app.use(createYmaps({
//     apikey: '',
//   }));
app
    .use(Maska)
    .mount('#app')