<template>
  <section class="section container-xl">
    <h1 class="title-section">
      Адреса магазинов
    </h1>
    <div class="wrap-yacard">   
      <yandex-map
        zoom="4"
        style="width: 100%; max-width: 100%; height: 700px;"
        :controls="controls"
        :coords="coords"
      >
        <ymap-marker
          v-for="(billboard, index) in surfaces"
          marker-type="placemark"
          :balloon-template="mapBalloon(billboard, index)"
          :coords="billboard.coords.split(',')"
          :marker-id="index"       
          :icon="markerIcon"
          :key="index"
        ></ymap-marker>
      </yandex-map>
    </div>
  </section>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
  name: "AddressComponent",
  components: { yandexMap, ymapMarker },
  data() {
    return {
      coords: [50.099943, 125.706567],
      controls: [],
      options: {
        layout: "islands#violetCircleIcon",
        imageSize: [30, 40],
        contentOffset: [0, 0]
      },
      markerIcon: {
        layout: 'islands#violetCircleIcon'
      },
      selectedSurfaces: this.$selectedSurfaces,
      layout:
        "<div>{{ properties.balloonContentHeader }}</div><div>{{ properties.balloonContentBody }}</div><div>{{ properties.balloonContentFooter }}</div>",
      surfaces: [{
          "name": "Стройка на Амурском",
          "adress": "Хабаровск, Амурский бульвар, 40",
          "coords": "48.484971, 135.066381"
        }, {
          "name": "Стройка на Проспекте",
          "adress": "Хабаровск, Проспект 60-летия Октября, 152",
          "coords": "48.479554, 135.114094"
        }, {
          "name": "Стройка на Пушкина,17 Уссурийск",
          "adress": "Уссурийск, ул. Пушкина, 17",
          "coords": "43.798658, 131.943651"
        }, {
          "name": "Стройка на Некрасова,142 Уссурийск",
          "adress": "Уссурийск, ул. Некрасова, 142",
          "coords": "43.814046, 131.947683"
        }, {
          "name": "Стройка на Дзержинского,8 Биробиджан",
          "adress": "Биробиджан, ул. Дзержинского, 8",
          "coords": "48.783981, 132.938934"
        }, {
          "name": "Стройка на Пионерской,13 Комсомольск",
          "adress": "Комсомольск-на-Амуре, ул. Пионерская, 13",
          "coords": "50.538131, 137.028427"
        }, {
          "name": "Стройка на Калинина,5 Владивосток",
          "adress": "Владивосток ул. Калинина, 5",
          "coords": "43.103088, 131.930305"
        }, {
          "name": "Стройка на Столетии,113 Владивосток",
          "adress": "Владивосток, Проспект 100-летия Владивостока, 113 А",
          "coords": "43.169457, 131.915057"
        }, {
          "name": "100ДЕЛ в ТЦ Виктория",
          "adress": "Владивосток ул. Бородинская 46\/50, ТЦ Виктория, павильон 121",
          "coords": "43.167639, 131.935960"
        }, {
          "name": "Стройка на Мира,36 Комсомольск",
          "adress": "Комсомольск-на-Амуре, Проспект Мира, 36",
          "coords": "50.550666, 137.018266"
        }, {
          "name": "100ДЕЛ на Тихоокеанской",
          "adress": "Хабаровск, ул. Тихоокеанская, 204 к 3",
          "coords": "48.554658, 135.047345"
        }, {
          "name": "Стройка на Мухина,118 Благовещенск",
          "adress": "Благовещенск, ул. Мухина, 118",
          "coords": "50.280203, 127.513837"
        }, {
          "name": "Стройка на Железнодорожной,37 Южно-Сахалинск",
          "adress": "Южно-Сахалинск, ул. Железнодорожная, 37",
          "coords": "46.960458, 142.722606"
        }, {
          "name": "Хабаровск, пер.Гаражный № 7 Плитка",
          "adress": "Хабаровск, пер. Гаражный, 4В",
          "coords": "48.481618, 135.114490"
        }, {
          "name": "Стройка на М.Личенко",
          "adress": "Дальнереченск, ул. Михаила Личенко, 33",
          "coords": "45.931050, 133.736499"
        }, {
          "name": "100ДЕЛ на Ленина",
          "adress": "Белогорск, ул. Ленина, 59",
          "coords": "50.920850, 128.469668"
        }, {
          "name": "Стройка на Комсомольской",
          "adress": "Находка, Комсомольская,36Б (ТЦ Искра)",
          "coords": "42.846932, 132.890933"
        }, {
          "name": "100ДЕЛ на Чайковского",
          "adress": "Ангарск, 90-й квартал, стр. 4",
          "coords": "52.534822, 103.901966"
        }, {
          "name": "Стройка на Трактовой",
          "adress": "Иркутск, ул. Трактовая, 14\/2",
          "coords": "52.320486, 104.236176"
        }, {
          "name": "Стройка на Базовой",
          "adress": "Комсомольск-на-Амуре, ул. Базовая, 5\/1",
          "coords": "50.560167, 137.030460"
        }, {
          "name": "Стройка на Автомобилистов",
          "adress": "Улан-Удэ, проспект Автомобилистов, 1 строение 1",
          "coords": "51.838549, 107.651534"
        }, {
          "name": "Стройка на Сергеева",
          "adress": "Иркутск, ул. Сергеева, 3Б\/1 ТЦ \"MegaHome\"",
          "coords": "52.266721, 104.223033"
        },{
          "name": "Стройка на Первомайской",
          "adress": "Иркутская область, г. Черемхово, ул. Первомайская, 205",
          "coords": "53.147126, 103.067602"
        }, {
          "name": "100ДЕЛ Братск Центр",
          "adress": "Братск, проезд Индустриальный, 8е",
          "coords": "56.138849, 101.622824"
        }, {
          "name": "Стройка на Жуковского",
          "adress": "Арсеньев, улица Жуковского, 66\/1",
          "coords": "44.152532, 133.274783"
        }, {
          "name": "Стройка на Стопани",
          "adress": "Услолье-Сибирское, Стопани, 20 к 10 (Ярмарка стройматериалов)",
          "coords": "52.752233, 103.626525"
        }, {
          "name": "100ДЕЛ на Советской",
          "adress": "Искитим, ул. Советская, 220",
          "coords": "54.639648, 83.309220"
        }, {
          "name": "100ДЕЛ на Ине, Новосибирск",
          "adress": "Новосибирск, ул. Первомайская, 45",
          "coords": "54.972034, 83.080222"
        }, {
          "name": "Стройка Братск Энергетик",
          "adress": "Братск, проезд Стройиндустрии, 50",
          "coords": "56.306328, 101.740171"
        }, {
          "name": "100ДЕЛ на Хасановской, Новосибирск",
          "adress": "Новосибирск, улица Хасановская,2",
          "coords": "54.984806, 82.857826"
        }, {
          "name": "100Дел на Володарского",
          "adress": "Тулун, улица Володарского, 14",
          "coords": "54.562512, 100.577555"
        }]


        
    };
  },
  mounted() {
    console.log(this.$selectedSurfaces[0]);
  },
  watch: {
    selectedSurfaces() {
      this.makeSurfaceSelected(
        this.selectedSurfaces[this.selectedSurfaces.length - 1].SURFACEID
      );
    }
  },
  methods: {
    selectedBillboard(billboard) {
      let data = {
        SURFACEID: billboard.surface_id,
        NETWORKID: billboard.networkid
      };
      return data;
    },
    makeSurfaceSelected: function(surface_id) {
      this.surfaces.forEach(surface => {
        if (surface.surface_id === surface_id) {
          surface.selected = true;
        }
      });
    },
    mapBalloon: function(billboard, index) {
      return `
      <div><h1 style="font-size:25px">${billboard.name}</h1>  
      <p><strong>Адрес</strong>: ${billboard.adress}</p>
      </div>
      `;
    }
  }
}
</script>

<style lang="scss" scoped>
section.ymap-container * {
  color: #000;    
}


.section {
  margin-top: 180px;
  overflow: hidden;

  @media(max-width: 1400px) {
    margin-top: 104px;
  }

  @media(max-width: 968px) {
    margin-top: 100px;
  }

  @media(max-width: 768px) {
    margin-top: 80px;
  }

  @media(max-width:420px) {
    margin-top: 20px;
  }
}

.title-section {
    font: 700 70px/105.6px "Montserrat ExtraBold";
    text-transform: uppercase;
    color: #000;

    @media(max-width: 1200px) {
      padding-left: 30px;
      font: 700 5vw/105.6px "Montserrat ExtraBold";
      position: relative;
      top: 50px;
    }

    @media(max-width: 768px) {
      font: 700 8vw/60.6px "Montserrat ExtraBold";
    }

    @media(max-width: 590px) {
      font: 700 39px / 40.6px "Montserrat ExtraBold";
      width: 80%;
      margin-bottom: 30px
    }

    @media(max-width: 480px) {
      font: 700 34px / 37.6px "Montserrat ExtraBold";
      width: 90%;
      padding-left: 0px !important;
    }

    @media(max-width: 410px) {
    font: 700 32px/34.6px "Montserrat ExtraBold";
  }
}

.wrap-yacard {
  position: relative;
  top: 74px;
  border-radius: 42px;
  overflow: hidden;
  width: 100%;
  max-height: 715px;

  @media(max-width: 1200px) {
    width: 95%;
    margin: 0 auto;
  }

  @media(max-width: 768px) {
    width: 90%;
  }

  @media(max-width: 590px) {
    top: 60px;
    max-height: 530px;
  }

  @media(max-width: 480px) {
    max-height: 450px;
  }

  @media(max-width: 410px) {
    max-height: 350px !important;
    border-radius: 20px;
  }

  

  & iframe {
    width: 100%;
    height: 715px;
    @media (max-width: 768px) {
      height: 443px;
    }
  }
}
</style>