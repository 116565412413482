<template>

  <section class="container-xl gift" :style="{ backgroundImage: `url(${background})` }">
    <h1 class="title-section">
      Что можно выиграть?
    </h1>
    <div class="image-container">
      <div class="col left">
        <template v-for="(image, index) in imagesLeft" :key="index">
          <div :class="`image-box ${image.class}`">
            <img :src="image.src" alt="" class="item" :class="`${image.class} ${image.anim}`"
              :style="`--delay: ${Math.random() * 1}s;`" />
          </div>
        </template>
      </div>
      <div class="col right">
        <template v-for="(image, index) in imagesRight" :key="index">
          <div :class="['image-box', image.class, { 'shiny-image-container wobble': image.class === 'sertificat' }]">
            <img :src="image.src" alt="" class="item" :class="`${image.class} ${image.anim}`"
              :style="`--delay: ${Math.random() * 2}s;`" />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      background: '',
      imagesRight: [
        {
          src: '/assets/image/2024/1.png',
          class: 'sertificat',
          anim: 'shiny'
        },
        {
          src: '/assets/image/2024/3.png',
          class: 'whatch',
          anim: 'updown'
        },
        {
          src: '/assets/image/2024/6.png',
          class: 'tools',
          anim: 'updown'
        },


      ],
      imagesLeft: [
        {
          src: '/assets/image/2024/2.png',
          class: 'money',
          anim: 'wobble'
        },

        {
          src: '/assets/image/2024/4.png',
          class: 'headphones ',
          anim: 'updown'
        },

        {
          src: '/assets/image/2024/5.png',
          class: 'powerbank',
          anim: 'wobble'
        },

      ]
    }
  },
  mounted() {
    this.setNewBackground();
    window.addEventListener('resize', this.setNewBackground);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setNewBackground);
  },
  methods: {
    setBackground() {
      if (window.innerWidth < 768) {
        this.background = '/assets/image/2024/backegroung-mobile.png';
      } else {
        this.background = '/assets/image/2024/backegroung-desktop.png';
      }
    },
    setNewBackground() {
      if (window.innerWidth < 768) {
        this.background = '/assets/image/2024/background-mobile-new.png';
      } else {
        this.background = '/assets/image/2024/background-desktop-new.png';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gift {
  margin-top: 96px;
  background: white;
  border-radius: 60px;
  padding-top: 50px;
  background-size: 80%;
  background-position-y: 64%;
  background-position-x: center;
  background-repeat: no-repeat;


  @media (max-width: 1370px) {
    background-size: 80vw;
    background-position-y: 50%;
    background-position-x: 9vw;
    padding-top: 20px;
  }

  @media(max-width: 768px) {
    background-size: 80vw;
    // background-position-y: 50%;
    // background-position-x: 9vw;
    padding-top: 20px;
  }

  .title-section {
    font: 700 70px/105.6px 'Montserrat ExtraBold';
    text-transform: uppercase;
    text-align: center;
    color: black;

    @media (max-width: 1370px) {
      font: 700 4vw/105.6px 'Montserrat ExtraBold';
    }

    @media(max-width: 768px) {
      font: 56px/110% 'Montserrat ExtraBold';
      text-align: center;
      width: 100%;
    }

    @media (max-width: 476px) {
      font: 24px/26px 'Montserrat Semibold';
    }
  }
}

.image-container {
  position: relative;
  padding-top: 50px;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1370px) {
    padding-top: 0px;
  }

  /* Добавляет пространство между изображениями */
  .col {
    height: 1650px;
    width: 50%;

    @media (max-width: 1370px) {
      height: 130vw;
    }

    @media (max-width: 768px) {
      height: 130vw;
      width: 100%;
    }

    .image-box {
      flex: 1 1 calc(50% - 10px);
      /* Две колонки с учетом пространства между изображениями */
      position: relative;

      .money {
        width: 614px;
        right: 150px;
        bottom: 141px;
        position: relative;

        @media (max-width: 1370px) {
          width: 50vw;
          bottom: 10vw;
          right: 13vw;
        }

        @media(max-width: 768px) {
          width: 25vw;
        }
      }

      .sertificat {
        width: 478px;
        left: 130px;
        position: relative;

        @media (max-width: 1370px) {
          width: 35vw;
          left: 10vw;
        }

        @media(max-width: 768px) {
          width: 25vw;
        }
      }

      .headphones {
        width: 419px;
        right: 114px;
        top: 140px;
        position: relative;

        @media (max-width: 1370px) {
          width: 40vw;
          top: 10vw;
          right: 13vw;
        }

        @media(max-width: 768px) {
          width: 25vw;
          bottom: 78vw;
        }
      }

      .whatch {
        width: 521px;
        top: 55px;
        left: 190px;
        position: relative;

        @media (max-width: 1370px) {
          width: 36vw;
          left: 14vw;
          top: 9vh;
        }
        
        @media(max-width: 868px) {
          width: 36vw;
          left: 11vw;
          top: 4vh;
        }

        @media(max-width: 768px) {
          width: 25vw;
          left: 14vw;
          top: 9vh;
        }
      }

      .powerbank {
        width: 519px;
        left: 45px;
        position: relative;
        bottom: 120px;

        @media (max-width: 1370px) {
          width: 34vw;
          left: 8vw;
        bottom: 10vw;
      }
        

        @media(max-width: 768px) {
          width: 25vw;
        }
      }

      .tools {
        width: 519px;
        left: 190px;
        position: relative;
        top: 230px;

        @media (max-width: 1370px) {
          width: 40vw;
          top: 17vh;
        }

        @media(max-width: 868px) {
          top: 13vh;
          left: 12vw;
        }

        @media(max-width: 768px) {
          width: 25vw;
        }
      }
    }
  }
}

@keyframes updown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes wobble {

  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-10px) rotate(-2deg);
  }
}

.updown {
  animation: updown 2s ease-in-out infinite;
  animation-delay: var(--delay);
}

.wobble {
  animation: wobble 2s ease-in-out infinite;
  animation-delay: var(--delay);
}


.shiny-image-container {
  position: relative;
  overflow: hidden;
  /* Чтобы блеск не выходил за границы контейнера */
}

// .shiny {
//     display: block;
// }

.shiny-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  /* Начинает за пределами изображения */
  width: 25%;
  height: 100%;
  // background: linear-gradient(93deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(93deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(255, 255, 255, 0) 100%);
  transform: skewX(-25deg);
  pointer-events: none;
  /* Чтобы блеск не мешал взаимодействию с изображением */
  animation: shine 3s ease-in-out infinite;
  z-index: 100;
}

@keyframes shine {
  0% {
    left: -150%;
  }

  100% {
    left: 150%;
  }
}
</style>
