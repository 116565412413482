<template>
  <section class="section-winner container-xl">
    <h1 class="title-section">Победители</h1>
    <div class="wrap-items">
      <div class="item">
        <a class="item-title" href="/winners1.xlsx">
          01 ТУР
        </a>
      </div>
      <div class="item">
        <a class="item-title" href="/winners2.xlsx">
          02 ТУР
        </a>
      </div>
      <div class="item">
        <a class="item-title" href="/winners3.xlsx">
          03 ТУР
        </a>
      </div>
      <div class="item">
        <a class="item-title" href="/winners4.xlsx">
          04 ТУР
        </a>
      </div>
      <div class="item big-item">
        <a class="item-title grand-item-title" href="/winners5.xlsx">
          Гранд-финал
        </a>
      </div>
    </div>
  </section>
</template>

<script>
//download для кнопки с файлом
export default {
  name: "Winners"
}
</script>

<style lang="scss" scoped>
section {
  .disabled {
    cursor: text;
  }

  .title-section {
    font: 700 70px / 105.6px "Montserrat ExtraBold";
    text-transform: uppercase;
    color: #000;

    @media(max-width: 1200px) {
      font: 700 5vw / 105.6px "Montserrat ExtraBold";
      padding-left: 30px;
    }

    @media(max-width: 768px) {
      font: 700 8vw/60.6px "Montserrat ExtraBold";
    }

    @media(max-width: 590px) {
      font: 700 39px / 40.6px "Montserrat ExtraBold";
      width: 80%;
      margin-bottom: 30px
    }

    @media(max-width: 480px) {
      font: 700 34px / 37.6px "Montserrat ExtraBold";
      width: 90%;
      padding-left: initial !important;
    }

    @media(max-width: 410px) {
      font: 700 32px/34.6px "Montserrat ExtraBold";
    }
  }


  .wrap-items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
    gap: 10px;

    @media(max-width: 1400px) {
      flex-wrap: wrap;
    }

    @media(max-width: 1200px) {
      margin-top: 88px;
    }

    @media(max-width: 772px) {
      display: block;
    }

    .big-item {
      @media(max-width: 1400px) {
        width: 99% !important;
      }
    }

    .item {
      min-width: initial;

      @media(max-width: 1400px) {
        flex: initial !important;
        margin-right: initial !important;
        width: 24%;
      }

      @media(max-width: 1200px) {
        margin-right: 12px;
      }

      @media(max-width: 772px) {
        width: 100%;
      }
      .item-title.disabled {
        border: 2px solid #c9c9c9;
        color: #c7c7c7 !important;

      }
      .item-title {
        border: 2px solid #EA5707;
        background-color: #fff;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 195px;
        height: 100px;
        font: 700 24px/30px 'Montserrat ExtraBold';
        color: #EA5707 !important;

        @media(max-width: 1400px) {
          width: initial !important;
        }

        @media(max-width: 500px) {
          height: 90px;
          font: 700 20px / 24px "Montserrat ExtraBold";
        }

        @media(max-width: 420px) {
          height: 80px;
          font: 700 16px / 24px "Montserrat ExtraBold";
          border-radius: 12px;
        }
        @media(max-width: 400px) {
          height: 65px;
          border-radius: 10px;
        }

        &:active,
        &:focus,
        &:hover {
          background-color: #EA5707;
          color: #fff !important;
        }
      }

      .grand-item-title {
        width: 300px;

        @media(max-width: 1400px) {
          width: 100%;
          position: relative;
          top: -15px;
        }

        @media(max-width: 772px) {
          top: initial !important;
          width: 101% !important;
        }
      }
    }
  }
}
</style>