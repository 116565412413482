<template>
  <section class="section container-xl">
    <h1 class="title-section">Регистрация чека</h1>
    <div class="wrap-form">
      <div class="form-result" :class="{ active: result }">
        <span class="toggleMenu close" @click="close"></span>
        <div class="result">
          <p>{{ messagePreTitle }}</p>
          <p>{{ messageTitle }}</p>
          <p>{{ messageSubtitle }}</p>
        </div>
      </div>
      <form action="" class="form" @submit.prevent="FormSubmit">
        <div class="form__fields">
          <div class="form__col">
            <div class="form-group">
              <label for="FN">ФН</label>
              <input id="FN" v-model.trim="FN" type="number" class="input input-text"
                :class="{ error_required: v$.FN.$dirty && v$.FN.required && FN === '' }"
                placeholder="9960440302440760" />
              <sapn class="small">Находится в самом низу чека</sapn>
            </div>
            <div class="form-group select_form">
              <label class="margin" for="" :class="[{ active: activate }]">Место расчета</label>
              <div class="__select" @click="openSelect" :class="[
                { active: select },
                {
                  error_required:
                    v$.MESTO_RASCHETA.$dirty &&
                    v$.MESTO_RASCHETA.required &&
                    MESTO_RASCHETA === '',
                },
              ]">
                <div class="__select__title">{{ selected }}</div>
                <div class="__select__content">
                  <div class="__select__item" v-for="option in options">
                    <input :id="option" class="__select__input" type="radio" v-model.trim="MESTO_RASCHETA"
                      :value="option" v-model="selected" />
                    <label @click="openSelect" :for="option" class="__select__label">{{
                      option
                    }}
                    </label>
                  </div>
                </div>
              </div>
              <sapn class="small">Выберите из списка</sapn>
            </div>

            <div class="form-group">
              <label for="PHONE">Номер телефона</label>
              <input id="PHONE" type="text" class="input input-text" :class="{
                error_required: v$.PHONE.$dirty && v$.PHONE.required && PHONE === '',
              }" v-model.trim="PHONE" placeholder="+7 " v-maska="'+7 (###) ###-####'" />

            </div>
          </div>

          <div class="form__col">
            <div class="form-group">
              <label for="FD">ФД</label>
              <input id="FD" v-model.trim="FD" type="number" class="input input-text"
                :class="{ error_required: v$.FD.$dirty && v$.FD.required && FD === '' }" placeholder="5252" />
              <sapn class="small">Находится в самом низу чека</sapn>
            </div>

            <div class="form-group">
              <label for="DATA_POKUPKI">Дата покупки</label>

              <input id="DATA_POKUPKI" type="text" class="input input-text" v-model.trim="DATA_POKUPKI" :class="{
                error_required:
                  v$.DATA_POKUPKI.$dirty &&
                  v$.DATA_POKUPKI.required &&
                  DATA_POKUPKI.length < 10                  
              }" placeholder="01/07/2024" v-maska="'##/##/####'" minlength="10" />

              <sapn class="small">Указана на чеке</sapn>
            </div>

            <div class="form-group">
              <label for="FIO">Ваши Фамилия Имя Отчество</label>
              <input id="FIO" type="text" class="input input-text" v-model.trim="FIO" :class="{
                error_required: v$.FIO.$dirty && v$.FIO.required && FIO === '',
              }" placeholder="Иванов Алексей Иванович" />
            </div>
          </div>
        </div>

        <div class="form__actions">
          <div class="form-group">
            <input type="checkbox" class="checkbox-input" id="checkbox" checked />
            <label for="checkbox" class="confirm-text">
              <span class="checkbox"> </span>
              <span class="confirm-span">
                Даю согласие на обработку персональных данных
              </span>
            </label>
          </div>
          <div class="form-group">
            <button class="btn-orange btns">Хочу участвовать</button>
          </div>
        </div>
      </form>

      <div class="img-check">
        <div class="img-rules shadow-rad_purple">
          <img src="/assets/image/2024/instrument1.png" alt="" class="instrument1" />
        </div>
        <img src="/assets/image/products/check.png" class="img-check_inner" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import moment from "moment";
export default {
  name: "FormComponent",
  data() {
    return {
      moment: moment,
      form: "",
      result: false,
      messagePreTitle: "Ура!",
      messageTitle: "Ваш чек зарегистрирован.",
      messageSubtitle: "Больше зарегистрированных чеков — больше шансов получить подарки!",
      select: false,
      activate: false,
      selected: "Выберите магазин",
      options: [
        "Стройка на Столетии",
        "Стройка на Калинина",
        "100ДЕЛ в ТЦ Виктория",
        "Стройка на Некрасова",
        "Стройка на Пушкина",
        "100ДЕЛ на Комсомольской",
        "Стройка на М. Личенко",
        "100ДЕЛ на Железнодорожной",
        "Стройка на Жуковского",
        "Стройка на Амурском",
        "Стройка на Проспекте",
        "100ДЕЛ на Тихоокеанской",
        "Стройка на Пионерской",
        "Стройка на Мира",
        "Стройка на Базовой",
        "100ДЕЛ на Дзержинского",
        "100ДЕЛ на Ленина",
        "Стройка на Мухина",
        "100ДЕЛ на Чайковского",
        "100ДЕЛ на Трактовой",
        "100ДЕЛ на Сергеева",
        "Стройка на Автомобилистов",
        "100ДЕЛ на Первомайской",
        "100ДЕЛ на Братск Центр",
        "100ДЕЛ в Энергетике",
        "Стройка на Стопани",
        "100ДЕЛ на Володарской",
        "100ДЕЛ на Советской",
        "100ДЕЛ на Ине",
        "100ДЕЛ на Хасановской",
        "100ДЕЛ в Тогучине",
        "Интернет магазин",
      ],
      FIO: "",
      FN: "",
      FD: "",
      PHONE: "",
      MESTO_RASCHETA: "",
      DATA_POKUPKI: "",
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  methods: {
    close() {
      if (this.result) {
        return (this.result = false);
      }
    },
    determineTour(date) {
      if (!date) return null; // Возвращаем null, если дата не указана

      const tourDates = [
        { start: "2024-07-01", end: "2024-07-07", tour: "1" },
        { start: "2024-07-08", end: "2024-07-14", tour: "2" },
        { start: "2024-07-15", end: "2024-07-21", tour: "3" },
        { start: "2024-07-22", end: "2024-07-25", tour: "4" },
      ];

      // Преобразование введённой даты из формата DD/MM/YYYY в YYYY-MM-DD
      let [day, month, year] = date.split('/');

      if(year == '24')
        year = '2024'

      const formattedDate = `${year}-${month}-${day}`;

      // Проверка входит ли дата в один из туров
      const tour = tourDates.find(t => formattedDate >= t.start && formattedDate <= t.end);
      return tour ? tour.tour : 'Тур не найден';
    },
    FormSubmit() {
      this.v$.$reset();
      
      if (this.v$.$invalid) {
        this.v$.$touch();
        return;
      } else {

        const tour = this.determineTour(this.DATA_POKUPKI);
        
        const url = "/local/landing/ajax.php";
        const data = {
          FIO: this.FIO,
          FN: this.FN,
          FD: this.FD,
          PHONE: this.PHONE,
          MESTO_RASCHETA: this.MESTO_RASCHETA,
          DATA_POKUPKI: this.DATA_POKUPKI,
          TOUR: tour, // Добавление тура
        };

        const form = JSON.stringify(data);

        try {
          fetch(url, {
            method: "post", // или 'PUT'
            body: form, // данные могут быть 'строкой' или {объектом}!
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((response) => response.json())
            .then((json) => {
              this.result = true;
              this.message = json.message;
              this.FIO = "";
              this.FN = "";
              this.FD = "";
              this.PHONE = "";
              this.MESTO_RASCHETA = "";
              this.DATA_POKUPKI = "";
            });
        } catch (error) {
          console.error("Ошибка:", error);
        }
      }
    },
    openActive(e) {
      return (this.activate = !this.activate);
    },
    openSelect(e) {
      this.openActive();
      return (this.select = !this.select);
    },
  },
  validations() {
    return {
      FIO: {
        required,
      },
      FN: {
        required,
      },
      FD: {
        required,
      },
      PHONE: {
        required,
      },
      DATA_POKUPKI: {
        required,
      },
      MESTO_RASCHETA: {
        required,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.margin.active {
  margin-bottom: 72px !important;

  @media(max-width: 502px) {
    margin-bottom: 70px !important;
  }
}

.img-check {
  @media(max-width: 976px) {
    margin-bottom: 50px;
    position: relative;
    max-width: initial !important;
  }
}

.__select__item {
  label {
    font: 1vw/33px "Montserrat" !important;
    color: #000;

    @media (max-width: 1400px) {
      font: 1.2vw/33px "Montserrat" !important;
    }

    @media (max-width: 1000px) {
      font: 1.5vw/33px "Montserrat" !important;
    }

    @media(max-width: 768px) {
      font: 1.8vw/33px "Montserrat" !important;
    }

    @media(max-width: 568px) {
      font: 2.5vw/33px "Montserrat" !important;
    }
  }
}

.section {
  position: relative;
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 104px;
  }

  .title-section {
    font: 700 70px/105.6px "Montserrat ExtraBold";
    text-transform: uppercase;
    width: 70%;
    color: #000;
    

    @media(max-width: 1200px) {
      padding-left: 30px;
      font: 700 5vw/105.6px "Montserrat ExtraBold";
      position: relative;
      top: 50px;
      margin-bottom: 50px;
    }

    @media(max-width: 1000px) {
      margin-bottom: 50px;
    }

    @media (max-width: 768px) {
      font: 8vw/110% "Montserrat ExtraBold";
      text-align: left;
      width: 80%;
      margin-bottom: 100px;
    }

    @media(max-width: 480px) {
      margin-left: initial !important;
      padding-left: 0 !important;
    }

    @media (max-width: 448px) {
      font: 24px/26px "Montserrat ExtraBold";

    }
  }

  .wrap-form {
    position: relative;


    @media (min-width: 977px) {
      display: flex;
      margin-top: 73px;
      justify-content: space-between;
    }

    @media (max-width: 976px) {
      display: flex;
      flex-direction: column-reverse;
    }

    .form {
      @media(max-width: 976px) {}

      .form-group {
        .input {
          @media(max-width: 1000px) {
            font: 1.5vw / 110% "Montserrat" !important;
            position: relative;

            height: 57px;
          }

          @media(max-width: 768px) {
            font: 2.0vw / 110% "Montserrat" !important;
          }

          @media(max-width: 568px) {
            position: relative;

            font: 2.5vw / 110% "Montserrat" !important;
          }

          @media(max-width: 440px) {
            font: 3vw / 110% "Montserrat" !important;
          }

          &::placeholder {
            @media(max-width: 1000px) {
              font: 1.5vw / 110% "Montserrat" !important;
              position: relative;

            }

            @media(max-width: 768px) {
              font: 2.0vw / 110% "Montserrat" !important;
            }

            @media(max-width: 568px) {
              position: relative;

              font: 2.5vw / 110% "Montserrat" !important;
            }

            @media(max-width: 440px) {
              font: 3vw / 110% "Montserrat" !important;
            }
          }
        }

        label {
          @media(max-width: 600px) {
            margin-top: initial !important;
            justify-content: center;
          }

          .confirm-span {
            @media(max-width: 1400px) {
              font: 18px / 26px "Montserrat" !important;
            }

            @media(max-width: 1100px) {
              font: 16px / 20px "Montserrat" !important;
            }

            @media(max-width: 600px) {
              font: 15px / 20px "Montserrat" !important;
              width: 50% !important;
            }

            @media(max-width: 468px) {
              font: 13px / 20px "Montserrat" !important;
              width: 75% !important;
            }
          }
        }
      }
    }

    .form-result {
      display: none;
      background-image: url(../../public/assets/image/popup.png);
      background-repeat: no-repeat;
      background-size: cover;
      position: fixed;
      width: 800px;
      height: 35%;
      border-radius: 60px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;

      @media(max-width: 850px) {
        width: 600px;
        height: 30%;
        border-radius: 30px;
      }

      @media(max-width: 600px) {
        width: 100%;
      }

      @media(max-width: 540px) {
        height: 25%;
      }

      @media(max-width: 420px) {
        height: 24%;
      }


      &.active {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .result {
        font: 32px/40.6px "Montserrat ExtraBold";
        color: #fff;
        text-align: left;
        width: 81%;

        @media(max-width: 850px) {
          width: 90%;
        }

        @media(max-width: 450px) {
          padding-top: 20px;
        }

        p:nth-child(1) {
          position: relative;
          text-transform: uppercase;

          @media(max-width: 850px) {
            font: 28px/36.6px "Montserrat ExtraBold";
          }

          @media(max-width: 600px) {
            font: 5vw/110% "Montserrat ExtraBold";
          }
        }

        p:nth-child(2) {
          position: relative;
          text-transform: uppercase;
          top: -15px;

          @media(max-width: 850px) {
            font: 28px/36.6px "Montserrat ExtraBold";
          }

          @media(max-width: 600px) {
            font: 5vw/110% "Montserrat ExtraBold";
          }
        }

        p:nth-child(3) {
          font: 26px/40px "Montserrat";

          @media(max-width: 850px) {
            font: 20px/30.6px "Montserrat";
          }

          @media(max-width: 600px) {
            font: 3vw/140% "Montserrat";
          }

          @media(max-width: 420px) {
            font: 3.4vw/140% "Montserrat";
          }
        }
      }

      .close {
        width: 42px;
        height: 42px;
        display: block;
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 11;
        cursor: pointer;

        @media(max-width: 540px) {
          top: 20px;
        }

        @media(max-width: 400px) {
          width: 36px;
          height: 36px;
        }

        &:before,
        &:after {
          content: "";
          width: 42px;
          height: 2px;
          background: white;
          position: absolute;
          top: 20px;

          @media(max-width: 540px) {
            top: 20px;
          }
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 26px;

      @media (min-width: 977px) {
        &__fields {
          display: flex;

          .form__col {
            width: 100%;
          }
        }
      }

      &__actions {
        display: flex;

        @media(max-width: 600px) {
          display: block;
        }
      }

      label {
        font: 17px/10px "Montserrat Semibold";
        color: #000;

        &_item:hover {
          color: #EA5707 !important;
        }
      }

      .btn-orange {
        background: #6C6C6C;
        color: #fff;

        @media(max-width: 1000px) {
          font: 20px / 29px sans-serif;
          font-weight: 700;
        }

        @media(max-width: 600px) {
          margin-top: 20px;
          font: 20px / 29px sans-serif;
          font-weight: 700;
        }
      }

      .form-group {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        flex: 1 0 45%;
        margin-right: 37px;
        margin-bottom: 30px;

        .small {
          color: #6C6C6C;
          font: 1vw/10px "Montserrat";
          margin-top: 10px;

          @media(max-width: 1400px) {
            font: 1.2vw/10px "Montserrat";
          }

          @media(max-width: 768px) {
            font: 2vw / 10px "Montserrat";
          }

          @media(max-width: 440px) {
            font: 3vw / 10px "Montserrat" !important;
          }
        }

        &.select_form {
          position: relative;
        }

        .input {
          border: 2px solid #6c6c6c;
          border-radius: 16px;
          padding: 10px 20px;
          outline: none;
          font: 1vw/33px "Montserrat";
          background: #fff;

          &.error_required {
            border: 2px solid red;
          }
        }

        label {
          & .confirm-span {
            font: 20px/26px "Montserrat";
            width: 80%;
          }

          margin-bottom: 15px;

          &.confirm-text {
            display: flex;
            align-items: center;
            margin-bottom: unset;

            @media(max-width: 1500px) {
              margin-top: 30px;
            }

            @media(max-width: 468px) {
              justify-content: start;
              gap: 20px;
            }
          }
        }

        .checkbox-input {
          display: none;

          &:checked+label .checkbox:after {
            transform: translate(-50%, -50%) scale(1);
          }
        }

        .checkbox {
          border: 3px solid #EA5707;
          border-radius: 40px;
          width: 40px;
          height: 40px;
          display: inline-block;
          position: relative;
          margin-right: 10px;

          &:after {
            border-radius: 40px;
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 24px;
            height: 24px;
            background-color: #EA5707;
            transition: 0.3s;
          }
        }

        @media (max-width: 1501px) {
          margin-top: 30px;

          &:nth-child(1) {
            margin-top: unset;
          }
        }

        @media (max-width: 976px) {
          margin-right: 0px;
          flex: 1 1 100%;
        }
      }
    }

    .img-rules {
      position: absolute;
      top: -370px;
      right: -10px;

      img.instrument1 {
        transform: rotate(20deg);
        position: relative;
        left: 100px;
        top: 80px;
        width: 400px;

        @media(max-width: 1200px) {
          left: 70px;
        }

        @media(max-width: 1000px) {
          left: 182px;
          width: 56%;
          top: 160px;
        }

        @media(max-width: 976px) {
          left: 281px;
          width: 53%;
          top: 195px;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .img-check {
      max-width: 30%;

      img {
        max-width: 100%;
      }

      @media (max-width: 976px) {
        display: flex;
        justify-content: center;
      }

      @media (max-width: 502px) {
        img {
          width: 100%;
        }

        .form-group {
          flex: 1 1 100%;

          .input {
            font: 16px/19px "Montserrat";

            @media(max-width: 1000px) {
              font: 1.5vw/110% "Montserrat" !important;
            }

            padding: 16px;
            gap: 8px;
            border: 2px solid #ffffff;
            border-radius: 10px;
          }

          &:nth-child(1) {
            margin-top: 30px;
          }
        }

        .btn-orange {
          background: #6C6C6C;
          color: #fff;
        }
      }
    }
  }
}

.shadow-rad_purple:before {
  border-radius: 100%;
  content: "";
  width: 150px;
  height: 150px;
  opacity: 0.7;
  position: absolute;
  z-index: 0;
  top: 40px;
  right: 80px;
  background: radial-gradient(50% 50% at 50% 50%, #6d388d 0%, rgba(99, 41, 134, 0.8) 11.54%, rgba(91, 34, 130, 0.45) 100%);
  box-shadow: 20px 20px 220px 200px rgb(99 41 134 / 50%);
  mix-blend-mode: color-dodge;
}

.__select {
  z-index: 1;
  position: relative;
  max-height: 57px;
  display: flex;
  flex-direction: column;
  border: 2px solid #6C6C6C;
  border-radius: 16px;
  gap: 13.41px;
  overflow: hidden;
  cursor: pointer;
  padding: 10px 20px;
  backdrop-filter: blur(24px);
  background: white;

  &.error_required {
    border: 2px solid red;
  }

  &__title {
    font: 1vw/33px "Montserrat";
    color: #000;

    @media (max-width: 1000px) {
      font: 1.5vw/33px "Montserrat";
    }

    @media (max-width: 768px) {
      font: 1.8vw/33px "Montserrat";
    }

    @media(max-width: 568px) {
      font: 2.5vw / 33px "Montserrat";
      position: relative;
    }

    @media(max-width: 502px) {
      bottom: 7px;
    }

    @media(max-width: 440px) {
      font: 3vw / 33px "Montserrat";
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    transition: all 0.1s ease-out;
    width: 5px;
    right: 30px;
    top: 14px;
  }

  &:before {
    content: url(../../public/assets/image/products/form_array1.svg);
    display: block;
  }

  &:after {
    content: url(../../public/assets/image/products/form_array2.svg);
    display: block;
    opacity: 0;
  }

  @media (max-width: 502px) {
    border-radius: 12px;
    padding: 16px;
    max-height: 55px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 300px;
    height: 100%;
    margin: 0 -42.9268px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #000;
      border-radius: 50px;
      position: relative;
      width: 100px;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: 50px;
    }

    margin: 0 -16px;
  }

  &__item {
    color: #fff;
    cursor: pointer;

    .__select__label {
      padding: 9px 14.9268px;
      width: 100%;
      cursor: pointer;
      margin-bottom: 0 !important;
      font: 20px/33px "Montserrat";

      &:hover {
        color: #ea5707 !important;
      }

      @media (max-width: 502px) {
        padding: 16px;
        font: 16px/19px "Montserrat" !important;
      }
    }
  }

  &__input {
    display: none;
  }

  &.active {
    max-height: 500px;
    background: #fff;
    position: absolute;
    top: 25px;
    width: 100%;

    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 0;
    }
  }
}
</style>
