<template>
  <HeaderComponent class="container-xl"/>
  <SectionComponent1 @link="linkedForm($event)"/>
  <component :is="currentSectionComponent"/>
  <FormComponent id="form"/>
  <SectionComponent3 class="container-xl" @link="linkedForm($event)"/>
  <Winners/>
  <TimeComponent/>
  <AddressComponent/>
  <FooterComponent/> 
</template>

<script>

import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import SectionComponent1 from "@/components/SectionComponent1";
import SectionComponent2 from "@/components/SectionComponent2";
import SectionComponent2Mobile from "@/components/SectionComponent2Mobile";
import FormComponent from "@/components/FormComponent";
import Winners from "@/components/Winners";
import TimeComponent from "@/components/TimeComponent";
import SectionComponent3 from "@/components/SectionComponent3";
import AddressComponent from "@/components/AddressComponent";

export default {
  name: 'App',
  components: {
    HeaderComponent,
    SectionComponent1,
    SectionComponent2,
    SectionComponent2Mobile,
    SectionComponent3,
    FormComponent,
    TimeComponent,
    Winners,
    AddressComponent,
    FooterComponent
  },
  data() {
    return {
      isMobile: window.innerWidth < 768
    };
  },
  computed: {
    currentSectionComponent() {
      return this.isMobile ? 'SectionComponent2Mobile'  : 'SectionComponent2';
    }
  },
  methods: {
    linkedForm(event) {
      const blockID = event.target.dataset['href'].substr(1)
      document.getElementById(blockID).scrollIntoView(true);
      return false
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth < 768;
    }
  },
  created() {
    window.addEventListener('resize', this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateIsMobile);
  }
}
</script>

<style lang="scss">
@import "main";
</style>
